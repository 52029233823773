<template>
    <div id="customer-dashboard" v-if="customer" class="mt-4">
        <div class="row">
            <div class="col-md-9">
                <div v-if="section == 'main'">
                    <!-- <div class="tab-switcher mb-2">
                        <span class="tab-btn" :class="{ 'is-active' : currentTab == 'online-store' }" @click="currentTab = 'online-store'">Online Store</span>
                        <span class="tab-btn" :class="{ 'is-active' : currentTab == 'home-nursing' }" @click="currentTab = 'home-nursing'">Home Nursing</span>
                    </div> -->

                    <div v-show="currentTab == 'online-store'">
                        <h4>Account Overview</h4>
                        <div class="row pt-3">
                            <div class="col-md-6 mt-md-0 mt-2">
                                <div class="overview-box">
                                    <i class="fas fa-calendar-check"></i>
                                    <div>
                                        <span class="d-block">OVERALL APPOINTMENTS</span>
                                        <span class="d-block box-value">{{ totalAppointments }}</span>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6 mt-md-0 mt-2">
                                <div class="overview-box">
                                    <i class="fas fa-notes-medical"></i>
                                    <div>
                                        <span class="d-block">OVERALL CONSULTATION</span>
                                        <span class="d-block box-value">{{ totalConsultations }}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- <div class="row pt-3">
                            <div class="col-md-4">
                                <div class="overview-box">
                                    <i class="fas fa-coins"></i>
                                    <div>
                                        <span class="d-block">TOTAL SPEND</span>
                                        <span class="d-block box-value">RM{{ getNumberWithCommas(getTotalSpend()) }}</span>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-4 mt-md-0 mt-2">
                                <div class="overview-box">
                                    <i class="fab fa-dropbox"></i>
                                    <div>
                                        <span class="d-block">TOTAL ORDER</span>
                                        <span class="d-block box-value">{{ getTotalOrder() }}</span>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-4 mt-md-0 mt-2">
                                <div class="overview-box">
                                    <i class="fas fa-star"></i>
                                    <div>
                                        <span class="d-block">POINTS COLLECTED</span>
                                        <span class="d-block box-value">{{ getNumberWithCommas(getTotalPoint()) }} pts</span>
                                    </div>
                                </div>
                            </div>
                        </div> -->
                        <div class="mt-5">
                            <h5>Home Nursing</h5>

                            <div class="row mt-5 mx-0" style="background-color: #F4F6F9">
                                <div class="col-4 col-md">
                                    <div class="order-status-box px-3 py-4 text-center">
                                        <span class="text-center box-value" v-if="totalHomeNursing.nurse > 0">{{ totalHomeNursing.nurse }}</span>
                                        <img src="@/assets/icons/nurse.svg" class="mb-2" />
                                        <span class="d-block text-center small">Nurse</span>
                                    </div>
                                </div>
                                <div class="col-4 col-md">
                                    <div class="order-status-box px-3 py-4 text-center">
                                        <div
                                            class="text-center box-value"
                                            v-if="totalHomeNursing.physio > 0">
                                                {{ totalHomeNursing.physio }}
                                        </div>
                                        <img src="@/assets/icons/nurse.svg" class="mb-2" />
                                        <span class="d-block text-center small">Physio</span>
                                    </div>
                                </div>
                                <div class="col-4 col-md">
                                    <div class="order-status-box px-3 py-4 text-center">
                                        <div
                                            class="text-center box-value"
                                            v-if="totalHomeNursing.medical_assistant > 0">
                                                {{ totalHomeNursing.medical_assistant }}
                                        </div>
                                        <img src="@/assets/icons/medical-assistant.svg" class="mb-2" />
                                        <span class="d-block text-center small">Medical Assistant</span>
                                    </div>
                                </div>
                                <div class="col-4 col-md">
                                    <div class="order-status-box px-3 py-4 text-center">
                                        <div
                                            class="text-center box-value"
                                            v-if="totalHomeNursing.care_aide > 0">
                                                {{ totalHomeNursing.care_aide }}
                                        </div>
                                        <img src="@/assets/icons/ambulans.svg" class="mb-2" />
                                        <span class="d-block text-center small">Care Aide</span>
                                    </div>
                                </div>
                                <div class="col-4 col-md">
                                    <div class="order-status-box px-3 py-4 text-center">
                                        <div
                                            class="text-center box-value"
                                            v-if="totalHomeNursing.confinement > 0">
                                                {{ totalHomeNursing.confinement }}
                                        </div>
                                        <img src="@/assets/icons/nurse.svg" class="mb-2" />
                                        <span class="d-block text-center small">Confinement</span>
                                    </div>
                                </div>
                            </div>

                            <!-- <div class="row mt-5 mx-0" style="background-color: #F4F6F9">
                                <div class="col-4 col-md">
                                    <div class="order-status-box px-3 py-4 text-center">
                                        <span class="text-center box-value" v-if="getTotalOrderToPay() > 0">{{ getTotalOrderToPay() }}</span>
                                        <img src="@/assets/icons/money.svg" class="mb-2" />
                                        <span class="d-block text-center small">To Pay</span>
                                    </div>
                                </div>
                                <div class="col-4 col-md">
                                    <div class="order-status-box px-3 py-4 text-center">
                                        <div
                                            class="text-center box-value"
                                            v-if="getTotalOrderToShip() > 0">
                                                {{ getTotalOrderToShip() }}
                                        </div>
                                        <img src="@/assets/icons/box.svg" class="mb-2" />
                                        <span class="d-block text-center small">To Ship</span>
                                    </div>
                                </div>
                                <div class="col-4 col-md">
                                    <div class="order-status-box px-3 py-4 text-center">
                                        <div
                                            class="text-center box-value"
                                            v-if="getTotalOrderToReceive() > 0">
                                                {{ getTotalOrderToReceive() }}
                                        </div>
                                        <img src="@/assets/icons/truck.svg" class="mb-2" />
                                        <span class="d-block text-center small">To Receive</span>
                                    </div>
                                </div>
                                <div class="col-4 col-md">
                                    <div class="order-status-box px-3 py-4 text-center">
                                        <div
                                            class="text-center box-value"
                                            v-if="getTotalOrderCompleted() > 0">
                                                {{ getTotalOrderCompleted() }}
                                        </div>
                                        <img src="@/assets/icons/check-circle.svg" class="mb-2" />
                                        <span class="d-block text-center small">Completed</span>
                                    </div>
                                </div>
                                <div class="col-4 col-md">
                                    <div class="order-status-box px-3 py-4 text-center">
                                        <div
                                            class="text-center box-value"
                                            v-if="getTotalOrderCancelled() > 0">
                                                {{ getTotalOrderCancelled() }}
                                        </div>
                                        <img src="@/assets/icons/calendar-times.svg" class="mb-2" />
                                        <span class="d-block text-center small">Cancelled</span>
                                    </div>
                                </div>
                            </div> -->
                            
                            <div class="table-responsive">
                                <table class="table">
                                    <thead>
                                        <tr>
                                            <th></th>
                                            <th>Booking Number</th>
                                            <th>Date</th>
                                            <th>Type</th>
                                            <th>Status</th>
                                        </tr>
                                    </thead>
                                    <tbody v-if="homeNursingLists">
                                        <tr v-for="(item, ind) in homeNursingLists" :key="item.id">
                                            <td>{{ ind + 1 }}</td>
                                            <td><u>#{{ item.booking_number }}</u></td>
                                            <td>{{ getDateOnly(item.updatedAt) }}</td>
                                            <td>{{ item.category }}</td>
                                            <td>{{ item.booking_status }}</td>
                                        </tr>
                                        <tr v-if="homeNursingLists.length > 10">
                                            <td colspan="5" class="text-center"><i>Lists are limited up to 10 listings at account overview.</i></td>
                                        </tr>
                                    </tbody>
                                    <tbody v-else>
                                        <tr>
                                            <td colspan="5" class="text-center"> No data just yet.</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>

                        <div class="mt-5">
                            <h5>Telemedicine</h5>

                            <div class="row mt-5 mx-0" style="background-color: #F4F6F9">
                                <div class="col-4 col-md">
                                    <div class="order-status-box px-3 py-4 text-center">
                                        <span class="text-center box-value" v-if="totalConsultationItems.consultation > 0">{{ totalConsultationItems.consultation }}</span>
                                        <i class="fas fa-user-nurse mb-2" style="color: #a91a17; font-size: 20px;"></i>
                                        <span class="d-block text-center small">Consultation</span>
                                    </div>
                                </div>
                                <div class="col-4 col-md">
                                    <div class="order-status-box px-3 py-4 text-center">
                                        <div
                                            class="text-center box-value"
                                            v-if="totalConsultationItems.prescription > 0">
                                                {{ totalConsultationItems.prescription }}
                                        </div>
                                        <i class="fas fa-envelope mb-2" style="color: #a91a17; font-size: 20px;"></i>
                                        <span class="d-block text-center small">Prescription</span>
                                    </div>
                                </div>
                                <div class="col-4 col-md">
                                    <div class="order-status-box px-3 py-4 text-center">
                                        <div
                                            class="text-center box-value"
                                            v-if="totalConsultationItems.medical_cert > 0">
                                                {{ totalConsultationItems.medical_cert }}
                                        </div>
                                        <i class="fas fa-envelope mb-2" style="color: #a91a17; font-size: 20px;"></i>
                                        <span class="d-block text-center small">Medical Cert</span>
                                    </div>
                                </div>
                                <div class="col-4 col-md">
                                    <div class="order-status-box px-3 py-4 text-center">
                                        <div
                                            class="text-center box-value"
                                            v-if="totalConsultationItems.referral_letter > 0">
                                                {{ totalConsultationItems.referral_letter }}
                                        </div>
                                        <i class="fas fa-envelope mb-2" style="color: #a91a17; font-size: 20px;"></i>
                                        <span class="d-block text-center small">Referral Letter</span>
                                    </div>
                                </div>
                            </div>

                            <div class="table-responsive">
                                <table class="table">
                                    <thead>
                                        <tr>
                                            <th></th>
                                            <th>Consultation Name</th>
                                            <th>Date</th>
                                            <th>Payment Status</th>
                                            <th>Consultation Status</th>
                                            <th>Total Spent</th>
                                        </tr>
                                    </thead>
                                    <tbody v-if="consultationLists">
                                        <tr v-for="(item, ind) in consultationLists.slice(0, 10)" :key="item.id">
                                            <td>{{ ind + 1 }}</td>
                                            <td><u>{{ item.consultation_name }}</u></td>
                                            <td>{{ getDateOnly(item.updatedAt) }}</td>
                                            <td>
                                                <span
                                                    class="badge badge-pill font-weight-normal px-3 py-2 mb-0"
                                                    :class="item.payment_status && item.payment_status == 'paid' ? 'badge-success text-light' : 'badge-light text-muted'">
                                                        {{ item.payment_status ? item.payment_status : 'unpaid' }}
                                                </span>
                                            </td>
                                            <td>{{ item.status }}</td>
                                            <td>RM 15</td>
                                        </tr>
                                        <tr v-if="consultationLists.length > 10">
                                            <td colspan="5" class="text-center"><i>Lists are limited up to 10 listings at account overview.</i></td>
                                        </tr>
                                    </tbody>
                                    <tbody v-else>
                                        <tr>
                                            <td colspan="5" class="text-center"> No data just yet.</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>

                        <div class="mt-5">
                            <h5>Clinic / Dental Appointment</h5>

                            <div class="row mt-5 mx-0" style="background-color: #F4F6F9">
                                <div class="col-4 col-md">
                                    <div class="order-status-box px-3 py-4 text-center">
                                        <div
                                            class="text-center box-value"
                                            v-if="totalAppointments > 0">
                                                {{ totalAppointments }}
                                        </div>
                                        <i class="fas fa-hospital mb-2" style="color: #a91a17; font-size: 20px;"></i>
                                        <span class="d-block text-center small">Appointment</span>
                                    </div>
                                </div>
                            </div>

                            <div class="table-responsive">
                                <table class="table">
                                    <thead>
                                        <tr>
                                            <th></th>
                                            <th>Appointment Name</th>
                                            <th>Submission Date</th>
                                            <th>Appointment Date</th>
                                            <th>Status</th>
                                        </tr>
                                    </thead>
                                    <tbody v-if="appointmentLists">
                                        <tr v-for="(item, ind) in appointmentLists" :key="item.id">
                                            <td>{{ ind + 1 }}</td>
                                            <td><u>{{ item.appointment_name }}</u></td>
                                            <td>{{ getDateOnly(item.updatedAt) }}</td>
                                            <td>{{ getDateOnly(item.appointment_date_time) }}</td>
                                            <td>{{ item.status }}</td>
                                        </tr>
                                        <tr v-if="appointmentLists.length > 10">
                                            <td colspan="5" class="text-center"><i>Lists are limited up to 10 listings at account overview.</i></td>
                                        </tr>
                                    </tbody>
                                    <tbody v-else>
                                        <tr>
                                            <td colspan="5" class="text-center"> No data just yet.</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <!-- <div class="row mt-5 mx-0">
                            <div class="col-md-6">
                                <div class="chart-box">
                                    <canvas id="myChart01" style="height:150px;width:240px;"></canvas>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="chart-box">
                                    <canvas id="myChart02" style="height:150px;width:240px;"></canvas>
                                </div>
                            </div>
                        </div> -->
                    </div>
                    <!-- <div v-show="currentTab == 'home-nursing'">
                        <booking-list-comp />
                    </div> -->
                </div>
                <div v-else-if="section == 'wishlist'">
                    <wishlist-comp />
                </div>
            </div>
            <!-- <div class="col-md-3 order-0 order-md-1"> -->
            <div class="col-md-3">
                <h5 class="text-nowrap">Account Details</h5>
                <div id="account-detail">
                    <p class="font-weight-bold">{{ customer.user.username }}</p>
                    <!-- <p v-if="customer.billing_address">
                        {{ verifyAddressItem(customer.billing_address.company)? verifyAddressItem(customer.billing_address.company) + ',' : null }} <br v-if="verifyAddressItem(customer.billing_address.company)" />
                        {{ verifyAddressItem(customer.billing_address.address1)? verifyAddressItem(customer.billing_address.address1) + ',' : null }} <br v-if="verifyAddressItem(customer.billing_address.address1)" />
                        {{ verifyAddressItem(customer.billing_address.address2)? verifyAddressItem(customer.billing_address.address2) + ',' : null}} <br v-if="verifyAddressItem(customer.billing_address.address2)" />
                        {{ customer.billing_address.city }}, {{ customer.billing_address.zipcode }} <br />
                        {{ customer.billing_address.province }} {{ customer.billing_address.country }} <br />
                        {{ customer.billing_address.phone }}
                    </p> -->
                    <!-- <p v-else class="font-italic">
                        Please click here to create billing address.
                    </p> -->
                    <!-- <span class="d-block"><router-link class="text-primary" to="/customer">Account Overview</router-link></span> -->
                    <span class="d-block" data-toggle="modal" data-target="#accountOverviewModal">
                        <a class="text-primary" href="#">Profile Overview</a>
                    </span>
                    <!-- <span class="d-block"><router-link class="text-primary" to="/customer/dashboard?section=wishlist">My Wishlists</router-link></span>
                    <span class="d-block"><router-link class="text-primary" to="/customer/addresses">Manage Addresses</router-link></span> -->
                </div>
            </div>

            <!-- Modal -->
            <div class="modal fade" id="accountOverviewModal" tabindex="-1" role="dialog" aria-labelledby="accountOverviewModalLabel"
                aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered" role="document">
                    <div class="modal-content">
                        <div class="modal-header border-bottom-0" style="margin-bottom: -25px;">
                            <h5 class="modal-title" id="exampleModalLabel">Profile Overview</h5>
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <form>
                            <div class="modal-body">
                                <div v-if="isUpdateSuccess" class="mb-4" style="background: #52a952;color: #fff;padding: 10px 20px;border-radius: 5px;">
                                    <span>Your profile has been succesfully updated. The modal will be closed now.</span>
                                </div>
                                <h6>General</h6>
                                <div class="form-group">
                                    <label for="username">Username</label>
                                    <input type="text" class="form-control" id="username" v-model="profileItems.username"
                                        placeholder="Enter username" :disabled="!enableProfileUpdate">
                                    <!-- <small id="emailHelp" class="form-text text-muted">Your information is safe with
                                        us.</small> -->
                                </div>
                                <div class="form-group">
                                    <label for="email1">Email address</label>
                                    <input type="email" class="form-control" id="email1" v-model="profileItems.email"
                                        placeholder="Enter email" :disabled="!enableProfileUpdate">
                                </div>
                                <div class="form-group">
                                    <label for="nric">NRIC</label>
                                    <input type="text" class="form-control" id="nric" v-model="profileItems.nric"
                                        placeholder="Enter NRIC number" :disabled="!enableProfileUpdate">
                                </div>
                                <div class="form-group">
                                    <label for="phone">Phone Number</label>
                                    <input type="text" class="form-control" id="phone" v-model="profileItems.phone"
                                        placeholder="Enter phone number" :disabled="!enableProfileUpdate">
                                </div>
                                <div class="form-group mb-5">
                                    <label for="comp_name">Company Name (Optional) </label>
                                    <input type="text" class="form-control" id="comp_name" v-model="profileItems.company_name"
                                        placeholder="Enter company name" :disabled="!enableProfileUpdate">
                                </div>
                                <h6>Billing Address</h6>
                                <div class="form-group">
                                    <label for="address1">Address 1</label>
                                    <input type="text" class="form-control" id="address1" v-model="profileItems.address_1"
                                        placeholder="" :disabled="!enableProfileUpdate">
                                </div>
                                <div class="form-group">
                                    <label for="address2">Address 2</label>
                                    <input type="text" class="form-control" id="address2" v-model="profileItems.address_2"
                                        placeholder="" :disabled="!enableProfileUpdate">
                                </div>
                                <div class="form-group">
                                    <label for="city">City</label>
                                    <input type="text" class="form-control" id="city" v-model="profileItems.city"
                                        placeholder="" :disabled="!enableProfileUpdate">
                                </div>
                                <div class="form-group">
                                    <label for="state">State</label>
                                    <input type="text" class="form-control" id="state" v-model="profileItems.state"
                                        placeholder="" :disabled="!enableProfileUpdate">
                                </div>
                                <div class="form-group">
                                    <label for="Postcode">Postcode</label>
                                    <input type="text" class="form-control" id="Postcode" v-model="profileItems.postcode"
                                        placeholder="" :disabled="!enableProfileUpdate">
                                </div>
                            </div>
                            <div class="modal-footer border-top-0 d-flex justify-content-center">
                                <div v-if="!enableProfileUpdate">
                                    <button class="btn btn-primary" style="background-color: #a91a17; border-color: #a91a17;" @click="enableProfileUpdate = !enableProfileUpdate">
                                        Edit Profile
                                    </button>
                                </div>
                                <div v-else>
                                    <div v-if="!isLoadingProfileUpdate">
                                        <button class="btn btn-secondary mr-3" @click="enableProfileUpdate = !enableProfileUpdate">
                                            Cancel
                                        </button>
                                        <button class="btn btn-primary" style="background-color: #a91a17; border-color: #a91a17;" @click="updateProfile()">
                                            Update Profile
                                        </button>
                                    </div>
                                    <div v-else>
                                        <span><i>Updating your profile. Please wait...</i></span>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import API from './../../utils/API'
import moment from 'moment'
import Chart from 'chart.js'

import BookingListComp from './BookingList'
import WishlistComp from './Wishlist'

export default {
    components: {
        BookingListComp,
        WishlistComp
    },
    data(){
        return {
            section: 'main',
            customer: null,
            currentTab: 'online-store',
            bookingForms: null,
            bookingFormsAssignedProvider: [],
            totalConsultations: 0,
            totalAppointments: 0,
            totalHomeNursing: {
                nurse: 0,
                physio: 0,
                medical_assistant: 0,
                care_aide: 0,
                confinement: 0
            },
            homeNursingLists: null,
            totalConsultationItems: {
                consultation: 0,
                prescription: 0,
                medical_cert: 0,
                referral_letter: 0
            },
            consultationLists: null,
            appointmentLists: null,
            enableProfileUpdate: false,
            isLoadingProfileUpdate: false,
            profileItems: {
                username: null,
                email: null,
                nric: null,
                phone: null,
                company_name: null,
                address_1: null,
                address_2: null,
                city: null,
                state: null,
                postcode: null
            },
            isUpdateSuccess: false
        }
    },
    watch: {
        $route(to, from){
            // console.log('query : ', to.query.section)
            if(to.query.section && (to.query.section != from.query.section)) this.section = to.query.section
            else this.section = 'main'
        }
    },
    computed: {
        ...mapGetters(['currentCustomer']),
    },
    methods: {
        initChart(){
            //Setting data
            let orders = this.customer.orders.reverse()
            let totalOrderData = []
            let totalSpendData = []

            //calculate the order based on day
            let ind = 0
            let date = null
            for (let i = 0; i < orders.length; i++) {
                if(date && date == moment(orders[i].createdAt).format('MM/D/YYYY')) {
                    totalOrderData[ind - 1].data = totalOrderData[ind - 1].data + 1
                    totalSpendData[ind - 1].data = totalSpendData[ind - 1].data + parseFloat(orders[i].total_price)
                }
                else {
                    totalOrderData.push({
                        date: moment(orders[i].createdAt).format('MM/D/YYYY'),
                        data: 1
                    })
                    totalSpendData.push({
                        date: moment(orders[i].createdAt).format('MM/D/YYYY'),
                        data: parseFloat(orders[i].total_price)
                    })
                    ind++
                    date = moment(orders[i].createdAt).format('MM/D/YYYY')
                }
            }

            //init chart attrs
            var labels = []
            var totalOrderChartData = []
            var totalSpendChartData = []
            for (var i = 0; i < totalOrderData.length; i++) {
                labels.push(totalOrderData[i].date)
                totalOrderChartData.push(totalOrderData[i].data)
                totalSpendChartData.push(totalSpendData[i].data)
            }

            //init chart
            var ctx1 = document.getElementById('myChart01')
            var ctx2 = document.getElementById('myChart02')
            var myChart = new Chart(ctx1, {
                type: 'line',
                data: {
                    labels : labels,
                    datasets : [
                    {
                        label: 'Total Orders',
                        data : totalOrderChartData,
                        borderWidth: 2
                    }]
                },
                options: {
                    plugins: {
                        filler: {
                            propagate: true
                        }
                    }
                }
            })
            var myChart = new Chart(ctx2, {
                type: 'line',
                data: {
                    labels : labels,
                    datasets : [
                    {
                        label: 'Total Spend (RM)',
                        data : totalSpendChartData,
                        borderWidth: 2
                    }]
                },
                options: {
                    plugins: {
                        filler: {
                            propagate: true
                        }
                    }
                }
            })
        },
        verifyAddressItem(_item){
            if(_item != null && _item != '-' && _item != '' && _item != ' ') return _item
            else return null
        },
        getDateOnly(_strDate){
            return moment(_strDate, 'YYYYMMDD').format('LL')
        },
        getPaymentStatus(_val){
            switch (_val) {
                case 'pending':
                    return 'Pending';
                case 'not_paid':
                    return 'Not Paid';
                case 'paid':
                    return 'Paid';
            
                default:
                    'Pending';
            }
        },
        getDeliveryStatus(_val){
            switch (_val) {
                case 'pending':
                    return 'Pending';
                case 'unfulfilled':
                    return 'In Progress';
                case 'fulfilled':
                    return 'Delivered';
            
                default:
                    'Pending';
            }
        },
        getNumberWithCommas(x) {
            return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        },
        getTotalOrder(){
            return this.customer.orders.length
        },
        getTotalSpend(){
            let total = 0.00
            this.customer.orders.forEach(order => {
                total += order.total_price
            })
            return total.toFixed(2)
        },
        getTotalPoint(){
            let totalSpend = parseFloat(this.getTotalSpend())
            let totalPoint = Math.round(totalSpend / 2)
            return totalPoint
        },
        getTotalOrderCompleted(){
            let total  = 0
            this.customer.orders.forEach(order => {
                if(order.fulfillment_status == 'fulfilled') total++
            })
            return total
        },
        getTotalOrderToReceive(){
            let total  = 0
            this.customer.orders.forEach(order => {
                if(order.fulfillment_status == 'unfulfilled' && order.financial_status == 'paid' && order.tracking_no) total++
            })
            return total
        },
        getTotalOrderToPay(){
            let total  = 0
            this.customer.orders.forEach(order => {
                if(order.fulfillment_status == 'pending' && ['not_paid', 'pending'].includes(order.financial_status)) total++
            })
            return total
        },
        getTotalOrderToShip(){
            let total  = 0
            this.customer.orders.forEach(order => {
                if(order.fulfillment_status == 'unfulfilled' && order.financial_status == 'paid' && !order.tracking_no) total++
            })
            return total
        },
        getTotalOrderCancelled(){
            let total  = 0
            this.customer.orders.forEach(order => {
                if(order.cancelled_at) total++
            })
            return total
        },
        // async getBookingForms(){
        //     let res = await API.get(`/booking-forms?customer=${this.currentCustomer.user.customer.id}`)
        //     this.bookingForms = res.data
        // },
        // async getAssignedProvider(){
        //     for (let ind = 0; ind < this.bookingForms.length; ind++) {
        //         if(this.bookingForms[ind].provider_booking_jobs.length > 0){
        //             let assignedJob = this.bookingForms[ind].provider_booking_jobs.find(job => job.type_of_job == 'assigned')
        //             if(assignedJob) {
        //                 let resProvider = await API.get(`/provider-booking-jobs/${assignedJob.id}`)
        //                 this.bookingFormsAssignedProvider.push(resProvider.data.provider)
        //             } else this.bookingFormsAssignedProvider.push(null)
        //         } else this.bookingFormsAssignedProvider.push(null)
        //     }
        // },
        // getCategoryName(_category){
        //     switch (_category) {
        //         case 'nurse':
        //             return 'Nurse'
        //         case 'physio':
        //             return 'Physio'
        //         case 'medical_assistant':
        //             return 'Medical Assistant'
        //         case 'care_aide':
        //             return 'Care Aide'

        //         default:
        //             break;
        //     }
        // },
        // getBookingDate(_startDateTime){
        //     return moment(_startDateTime).format('dddd, D MMMM YYYY')
        // },
        // getBookingTime(_startDateTime, _endDateTime){
        //     return `${moment(_startDateTime).format('LT')} - ${moment(_endDateTime).format('LT')}`
        // }
        // verifyAddressAttribute(_attr){
        //     if(_attr && _attr != ''  && _attr != '-'  && _attr != ' ')
        //         return true
        //     else
        //      return false
        // }
        async initAllData(){
            if(this.customer.consultations && this.customer.consultations.length > 0){
                this.totalConsultations = this.customer.consultations.length

                this.totalConsultationItems.consultation = this.customer.consultations.length
                this.totalConsultationItems.prescription = this.customer.prescriptions.length
                const medicalCertCountRes = await API.get(`/medical-certificates/count?patient_nric_eq=${this.customer.nric}`)
                this.totalConsultationItems.medical_cert = medicalCertCountRes.data
                const referralLetterCountRes = await API.get(`/referral-letters/count?patient_nric_eq=${this.customer.nric}`)
                this.totalConsultationItems.referral_letter = referralLetterCountRes.data

                this.consultationLists = this.customer.consultations
            }

            if(this.customer.booking_forms && this.customer.booking_forms.length > 0){
                this.totalAppointments += this.customer.booking_forms.length

                this.totalHomeNursing.nurse = this.customer.booking_forms.filter(item => { return item.category == 'nurse' }).length
                this.totalHomeNursing.physio = this.customer.booking_forms.filter(item => { return item.category == 'physio' }).length
                this.totalHomeNursing.medical_assistant = this.customer.booking_forms.filter(item => { return item.category == 'medical_assistant' }).length
                this.totalHomeNursing.care_aide = this.customer.booking_forms.filter(item => { return item.category == 'care_aide' }).length
                this.totalHomeNursing.confinement = this.customer.booking_forms.filter(item => { return item.category == 'confinement' }).length

                this.homeNursingLists = this.customer.booking_forms
            }

            if(this.customer.appointments && this.customer.appointments.length > 0){
                this.totalAppointments += this.customer.appointments.length

                this.appointmentLists = this.customer.appointments
            }

            //setting profile
            this.profileItems.username = this.customer.user.username
            this.profileItems.email = this.customer.user.email
            this.profileItems.nric = this.customer.nric
            this.profileItems.phone = this.customer.default_phone
            this.profileItems.address_1 = this.customer.billing_address && this.customer.billing_address.address1 ? this.customer.billing_address.address1 : ''
            this.profileItems.address_2 = this.customer.billing_address && this.customer.billing_address.address2 ? this.customer.billing_address.address2 : ''
            this.profileItems.city = this.customer.billing_address && this.customer.billing_address.city ? this.customer.billing_address.city : ''
            this.profileItems.state = this.customer.billing_address && this.customer.billing_address.province ? this.customer.billing_address.province : ''
            this.profileItems.postcode = this.customer.billing_address && this.customer.billing_address.zipcode ? this.customer.billing_address.zipcode : ''
        },
        async updateProfile(){
            this.isLoadingProfileUpdate = true

            //make logic for updating profile here...not yet create !!!
            //And the profile info need to be updated or get from API at first and then!!!

            this.isUpdateSuccess = true
            $('#accountOverviewModal').scrollTop(0)
            setTimeout(() => {
                $('#accountOverviewModal').modal('hide')
                this.isUpdateSuccess = false
                this.enableProfileUpdate = false
                this.isLoadingProfileUpdate = false
            }, 2000);
        }
    },
    async mounted(){
        if(this.$route.query.maintab && this.$route.query.maintab == 'home_nursing')
            this.currentTab = 'home-nursing'
        // await this.getBookingForms()
        // await this.getAssignedProvider()
        if(this.$route.query.section) this.section = this.$route.query.section

        let resCustomer = await API.get(`/customers/${this.currentCustomer.user.customer.id}`)
        this.customer = resCustomer.data

        this.initAllData()

        // setTimeout(() => this.initChart(), 500) //wait for html to be fully loaded
    }
}
</script>

<style scoped>
#customer-dashboard {
    font-size: 15px;
}
#customer-dashboard h1, h2, h3, h4, h5 {
    line-height: 1.4;
    margin: 0 0 20px;
    /*font-family: "Roboto Slab",serif;*/
    text-transform: uppercase;
    font-weight: bold;
    color: #000;
}

.chart-box {
    /* border: 1px solid #9d9d9d; */
    /* padding: 25px 10px; */
    padding: 25px 0px;
}
.overview-box {
    /* border: 1px solid #9d9d9d; */
    padding: 15px 20px;
    background: rgba(169, 26, 24, 0.9);
    border-radius: 5px;
    color: #FFFFFF;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    line-height: 1.3;
}

.table th {
    color: #7CC6D6;
    font-weight: 500;
    border-top: none;
    border-bottom: none;
}

.table td {
    font-weight: 300;
    padding: 0.75rem 1rem;
}

.table,
#account-detail {
    /* font-family: "Karla",arial,sans-serif; */
    /* font-size: 15px; */
    font-size: 14px;
    line-height: 30px;
    color: #333;
}
#customer-dashboard a {
    color: #333;
}
#customer-dashboard a.text-primary {
    color: #a91a18!important;
}
#customer-dashboard a.text-primary:hover {
    color: #cc2c2a!important;
    text-decoration: underline;
}
#customer-dashboard a:hover {
    color: #000;
    text-decoration: unset;
}

.overview-box i {
    font-size: 26px;
    margin-right: 1rem;
}
.overview-box .box-value {
    font-size: 18px;
    font-weight: bold;
    /*font-family: "Roboto Slab",serif;*/
}

.order-status-box .box-value {
    color: #FFFFFF;
    font-size: 0.8rem;
    width: 20px;
    height: 20px;
    background-color: #DA1414;
    border-radius: 50%;
    position: absolute;
    top: 10px;
    right: calc(50% - 2.25rem);
}

.tab-switcher {
    height: 50px;
}
.tab-btn {
    padding: 7px 20px;
    cursor: pointer;
    border-bottom: 3px solid #DADEE3;
    border-radius: 0px;
    color: #A5ABB3;
    font-weight: 500;
}
.tab-btn.is-active {
    border-bottom: 3px solid #A91A18;
    border-radius: 0px;
    color: #A91A18;
    font-weight: 500;
}

.card.custom-card {
    border-radius: 9px;
    border: 1px solid #EBEEF2;
    font-size: 14px;
    /* cursor: pointer; */
}
.card.custom-card .btn {
    background-color: #7CC6D6;
    border-color: #7CC6D6;
    border-radius: 6px;
    font-size: 14px;
    padding: 10px 20px;
}
.card.custom-card a:hover {
    text-decoration: underline!important;;
}

.badge.custom-badge {
    padding: 7px 18px;
    background-color: rgba(169, 26, 24, 0.5);
    font-weight: 400;
    color: #fff;
}


@media (min-width: 768px) {
    .chart-box.neg-mr-20,
    .overview-box.neg-mr-20 {
        margin-right: -20px;
    }
}
</style>