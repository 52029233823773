<template>
  <div id="customer-wishlist" v-if="currentCustomer">
      <div class="row mx-0">
          <div class="col">
              <h5>My Wishlists</h5>
          </div>
      </div>
      <div class="row mx-0">
          <div class="col">
                <div v-if="products" class="text-right mt-3 mb-3">
                    items {{ start + 1 }} - {{ (start) + products.length }} of {{ count }} total
                </div>
          </div>
      </div>
      <div id="products-wishlist" class="row mx-0">
        <div class="col-lg-12 col-md-12 col-sm-12 mt-4 mb-4">
            <div v-if="products && products.length > 0" class="products-list">
                <div class="row">
                    <div v-for="productItem in products" :key="productItem.id"
                        class="col-lg-3 col-md-3 col-6">
                        <div class="product-view">
                            <div class="row">
                                <div class="col-sm-12 bg-white">
                                    <div class="box">
                                        <div class="img-box" :style="{'background-image': `url(${getImageLink(productItem.product)})`}">
                                            <img :src="getPromoBadge(productItem.product)" alt="" class="badge-img">
                                        </div>
                                        <!-- <div v-if="productItem.product.is_sold_out" class="img-bg-sold-out"></div>
                                        <button v-if="!productItem.product.is_sold_out" class="btn" @click="addItemObj(productItem.product)">Add to Cart</button>
                                        <button v-else class="btn btn-sold-out" >Sold Out</button> -->
                                    </div>
                                </div>
                                <div class="col-sm-12 text-center">
                                    <router-link :to="`/store/products/${productItem.product.id}`"><span
                                            class="title">{{ productItem.product.title }}</span>
                                    </router-link>
                                    <div v-if="!isVIP">
                                        <!-- <span v-if="productItem.product.variants[0].compare_at_price && productItem.product.variants[0].compare_at_price != 0" class="price compare_at_price">RM
                                        {{ (productItem.product.variants[0].compare_at_price).toFixed(2) }}</span>
                                        <span class="price">RM
                                        {{ (productItem.product.variants[0].price).toFixed(2) }}</span> -->
                                        <div v-if="productItem.product.promo_price">
                                            <span class="price compare_at_price">RM {{ (productItem.product.variants[0].price).toFixed(2) }}</span>
                                            <span class="price">RM {{ (productItem.product.promo_price).toFixed(2) }}</span>
                                        </div>
                                        <div v-else>
                                            <span class="price">RM {{ (productItem.product.variants[0].price).toFixed(2) }}</span>
                                        </div>
                                    </div>
                                    <span v-else class="price">RM
                                        0.00</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div v-else-if="products && products.length == 0" class="products-list">
                There is no item with this information.
            </div>
        </div>
        <div class="col-lg-12 col-md-12 col-sm-12">
            <div v-if="products && products.length > 0" class="pagination-footer">
                <ul class="pagination justify-content-center">
                    <li class="page-item"
                        :class="{'disabled' : !$route.query.page || $route.query.page == '1'}">
                        <button class="page-link"
                            @click="goto(parseInt($route.query.page) - 1)">
                            <span aria-hidden="true">&laquo;</span>
                        </button>
                    </li>
                    <li class="page-item"><a class="page-link"
                            href="#">{{ $route.query.page ? $route.query.page : 1 }}</a></li>
                    <li class="page-item"
                        :class="{'disabled' : $route.query.page == overallPage || overallPage == 1}">
                        <button class="page-link"
                            @click="goto(parseInt($route.query.page ? $route.query.page : 1) + 1)">
                            <span aria-hidden="true">&raquo;</span>
                        </button>
                    </li>
                </ul>
            </div>
        </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import API from './../../utils/API'
import moment from 'moment'

import { checkProductPromo } from './../../utils/ProductUtils'

export default {
    data(){
        return {
            isVIP: false,
            products: null,
            start: 0,
            count: 0,
            overallPage: 1
        }
    },
    computed: {
        ...mapGetters(['currentCustomer', 'wishlist']),
    },
    methods: {
        initPromo(){
            this.products.forEach(item => {
                let prodPromo = checkProductPromo(item.product)
                if(prodPromo) { //craete new subproperties if promo exist
                    item.product.promo_price = prodPromo.promo_price
                    item.product.promo_badge_url = prodPromo.promo_badge
                }
            })
        },
        getImageLink(_product) {
            if(!_product.variants[0].image || _product.variants[0].image.length == 0) return '/img/default_no_image.jpg'
            else return API.getAPIUrl() + _product.variants[0].image[0].url //get the first image
        },
        getPromoBadge(_product){
            if(_product.promo_badge_url) return API.getAPIUrl() + _product.promo_badge_url
            // if(_product.is_promotion){
            //     if(_product.promo_type == 'raya') return '/img/badge_raya_promo.png'
            //     else if(_product.promo_type == 'limitedtime') return '/img/badge_4_days_only.png'
            // }
        },
    },
    async mounted(){
        this.products = this.wishlist.items
        this.count = this.products.length

        //Set all price to 0.00 if customer type == vip
        if(this.currentCustomer){
            if(this.currentCustomer.user.customer.cust_type == 'vip') this.isVIP = true
            else this.isVIP = false
        }

        //check product promo
        this.initPromo()
    }
}
</script>

<style scoped>
#customer-wishlist {
    font-size: 15px;
}
#customer-wishlist h1, h2, h3, h4, h5 {
    line-height: 1.4;
    margin: 0 0 20px;
    /*font-family: "Roboto Slab",serif;*/
    text-transform: uppercase;
    font-weight: bold;
    color: #000;
}

#products-wishlist .content-header {
    border-bottom: 2px solid #f6f6f6;
    margin-bottom: 10px;
}

#products-wishlist .content-header .card-title {
    position: absolute;
    bottom: 5px;
    letter-spacing: 0.2em;
    line-height: 1.4;
    /* font-family: "Roboto Slab", serif; */
    text-transform: uppercase;
    font-weight: bold;
    color: #000;
    font-size: 1.42857em;
}

#products-wishlist .products-list {
    border-bottom: 2px solid #f6f6f6;
}

#products-wishlist .products-list .product-view {
    margin-bottom: 25px;
}

#products-wishlist .products-list .product-view .box {
    position: relative;
    background-color: #FFFFFF;
}

.img-box {
    display: block;
    padding-top: 100%;
    overflow: hidden;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: contain;
}

/* #products-wishlist .products-list .product-view .box .img-bg-sold-out {
    width: 100%;
    height: 100%;
    top: 0px;
    left: 0px;
    position: absolute;
    background-color: black;
    opacity: 0.4;
} */

#products-wishlist .products-list .product-view .box button {
    opacity: 0;
    width: 100%;
    position: absolute;
    bottom: 12px;
    padding: 10px 10px;
    display: block;
    background-color: #fff;
    color: #000;
    border: unset;
    border-radius: unset;
    text-transform: uppercase;
    font-size: 14px!important;
    font-weight: bold;
    /* font-family: "Karla", sans-serif; */
}

#products-wishlist .products-list .product-view .box button.btn-sold-out {
    color: #a91a18;
    opacity: 1;
}

#products-wishlist .products-list .product-view .box .img-box:hover+button {
    opacity: 100;
    transition: 0.2s;
}

#products-wishlist .products-list .product-view .box button:hover {
    opacity: 100;
    transition: 0.2s;
    background-color: #000;
    color: #fff;
}

#products-wishlist .products-list .product-view .box button.btn-sold-out:hover {
    opacity: 100;
    transition: 0;
    background-color: #fff;
    color: #a91a18;
    cursor:initial;
}

#products-wishlist .products-list .product-view a {
    text-decoration: unset;
}

#products-wishlist .products-list .product-view .title {
    display: block;
    font-size: 14px;
    text-transform: none;
    letter-spacing: 0.12em;
    line-height: 1.4;
    /* font-family: "Roboto Slab", serif; */
    font-weight: bold;
    color: #666;
    margin-top: 15px;
}

#products-wishlist .products-list .product-view .price {
    display: block;
    color: #a91a18;
    font-size: 14px;
    /* font-family: "Karla", serif; */
    font-weight: bold;
    margin-top: 8px;
}
#products-wishlist .products-list .product-view .price.compare_at_price {
    text-decoration: line-through;
    color: gray;
    font-size: 12px;
    margin-bottom: -11px;
}

#products-wishlist .pagination-footer {
    width: 100%;
}

#products-wishlist .pagination-footer li a,
button {
    color: #666;
    border-radius: unset !important;
}

#products-wishlist .img-box img.badge-img {
        position: absolute;
        width: 50%;
        top: 5px;
    }
</style>